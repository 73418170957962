<template>
  <div class="layout-main">
    <div class="layout-table-basic">
      <div class="toolbar">
        <div class="search">
          <el-form :inline="true">
            <el-form-item label="关键字 ：">
              <el-input size="small" placeholder="请输入关键字" v-model="filterData.keyword" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="handleSearchReset">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="handle">
      
        </div>
      </div>

      <div class="table">
        <el-table v-loading="isTableloading" :data="tableData" stripe highlight-current-row style="width: 100%" height="100%">
          <el-table-column prop="" label="" width="10"> </el-table-column>
          <el-table-column prop="id" label="ID" width="40"> </el-table-column>
          <el-table-column prop="wechatRecordId" label="微信支付交易单号" min-width="100"> </el-table-column>
          <el-table-column prop="nickname" label="昵称" min-width="100"> </el-table-column>
          <el-table-column prop="tel" label="手机号" min-width="90"></el-table-column>
          <el-table-column prop="realname" label="客户姓名" min-width="90"></el-table-column>
          <el-table-column prop="chargeAmount" label="金额" min-width="60"> </el-table-column>
          <el-table-column prop="chargeTime" label="充值时间" min-width="120"></el-table-column>
          <el-table-column prop="chargestatus" label="状态" min-width="100">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.chargestatus == -2" type="info">用户未支付</el-tag>
              <el-tag v-if="scope.row.chargestatus == -1" type="info">充值失败</el-tag>
              <el-tag v-if="scope.row.chargestatus == 0" type="success">充值成功</el-tag>
              <el-tag v-if="scope.row.chargestatus == 1" type="danger">退款审核中</el-tag>
              <el-tag v-if="scope.row.chargestatus == 2">退款成功</el-tag>
              <el-tag v-if="scope.row.chargestatus == 3" type="warning">退款已驳回</el-tag>
              <el-tag v-if="scope.row.chargestatus == 4" type="info">退款失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="refundTime" label="退款时间" min-width="100"></el-table-column>
          <el-table-column prop="wechatRefundRecordId" label="微信退款交易单号" min-width="100"> </el-table-column>
          <!-- <el-table-column label="操作" fixed="right" width="200">

          </el-table-column> -->
        </el-table>
      </div>

      <div class="pagination">
        <el-pagination :page="searchParams.page" :page-size="searchParams.limit" :total="total" background layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
      </div>

      <!-- 添加、编辑，模态框组件 -->
      <el-dialog :title="dialogTitle" :visible="dialogVisible" @close="dialogVisible = false" width="600px">
        <el-form :model="form" ref="form" label-width="120px">
          <el-form-item label="拍卖名称" prop="salesTitle">
            <el-input v-model="form.salesTitle" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="拍卖公告" prop="salesNotice">
            <el-input v-model="form.salesNotice" placeholder="" type="textarea" :rows="10"></el-input>
          </el-form-item>
          <el-form-item label="截至时间" prop="salesEndtime">
            <el-date-picker v-model="form.salesEndtime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"> </el-date-picker>
          </el-form-item>
          <el-form-item label="车辆类型" prop="salesType">
            <el-select v-model="form.salesType">
              <el-option v-for="item in salesTypeOptions" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSubmit()">保存</el-button>
        </div>
      </el-dialog>

      <!-- 列表详情，抽屉组件 -->
      <el-drawer title="详情" :visible.sync="drawerDetailVisible">
        <div class="layout-drawer-handle">
          <el-button type="primary" size="small" @click="handleEdit(drawerDetailObj, 'form')">编辑</el-button>
          <el-popconfirm cancel-button-type="Primary" icon="el-icon-info" icon-color="red" :title="'您确定要删除【' + drawerDetailObj.salesTitle + '】记录吗？'" @confirm="handleDeleteConfirm(drawerDetailObj)" placement="bottom-end">
            <el-button type="danger" size="small" slot="reference">删除</el-button>
          </el-popconfirm>
        </div>
        <div class="layout-drawer-detail">
          <div class="layout-drawer-detail-top">
            <div class="layout-drawer-detail-top-title">
              基础信息
            </div>
          </div>
          <div class="layout-drawer-detail-main">
            <div class="layout-drawer-detail-list">
              <DrawerDetail liLabel="拍卖名称" :liValue="drawerDetailObj.salesTitle"></DrawerDetail>
              <DrawerDetail liLabel="拍卖公告" :liValue="drawerDetailObj.salesNotice"></DrawerDetail>
              <DrawerDetail liLabel="车辆类型" :liValue="drawerDetailObj.salesType" :valueArr="this.salesTypeOptions"></DrawerDetail>
              <DrawerDetail liLabel="关联车辆" :liValue="drawerDetailObj.carsNum"></DrawerDetail>
              <DrawerDetail liLabel="截止时间" :liValue="drawerDetailObj.salesEndtime"></DrawerDetail>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import Request from '@/api/record';

import DrawerDetail from '../../../layout/console/components/DrawerDetail.vue';
export default {
  components: {
    DrawerDetail,
  },

  data() {
    return {
      //列表相关
      tableData: null,
      isTableloading: false,
      total: 0,

      //查询区域字段
      filterData: {
        keyword: '',
      },


      //发送查询请求字段
      searchParams: {
        limit: 10,
        page: 1,
      },

      //模态框
      isEdit: false,
      idEdit: '',
      dialogVisible: false,
      dialogTitle: '添加',

      drawerDetailVisible: false,
      drawerDetailObj: {},

      form: {
        salesTitle: '',
        salesNotice: '',
        salesEndtime: '',
        salesType: 1,
      },
    };
  },

  mounted() {
    this.getTableList();
  },

  methods: {
    getTableList() {
      this.isTableloading = true;
      Request.page(this.searchParams).then((res) => {
        console.log(res);
        this.isTableloading = false;
        if (res && res.code === 200) {
          if (res.data.list.length > 0) {
            this.renderTable(res.data.list);
          } else {
            if (this.searchParams.page > 1) {
              this.searchParams.page--;
              this.getTableList();
            } else {
              this.renderTable(res.data.list);
            }
          }
          this.total = res.data.total;
        }
      });
    },

    // 渲染表格
    renderTable(data) {
      this.tableData = [];
      data.forEach((e) => {
        this.tableData.push(e);
      });
    },

    //查询搜索按钮
    handleSearch() {
      for (let key in this.filterData) {
        this.searchParams[key] = this.filterData[key];
      }

      for (let key in this.searchParams) {
        if (!this.searchParams[key]) {
          delete this.searchParams[key];
        }
      }

      this.searchParams.page = 1;
      this.getTableList();
    },

    //查询重置按钮
    handleSearchReset() {
      for (let key in this.filterData) {
        this.filterData[key] = '';
      }
      
      this.handleSearch();
    },

    //分页改变每页显示数量
    handleSizeChange(val) {
      this.searchParams.page = 1;
      this.searchParams.limit = val;
      this.getTableList();
    },

    //分页跳转至当前页
    handleCurrentChange(val) {
      this.searchParams.page = val;
      this.getTableList();
    },

    //添加按钮
    handleCreate(formName) {
      this.isEdit = false;
      this.dialogTitle = '添加';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = '';
        }
      });
    },

    //列表里操作列编辑按钮
    handleEdit(row, formName) {
      this.isEdit = true;
      this.idEdit = row.id;
      this.dialogTitle = '编辑';
      this.dialogVisible = true;

      this.$nextTick(() => {
        this.$refs[formName].resetFields();

        for (let key in this.form) {
          this.form[key] = row[key];
        }
      });
    },

    //添加、编辑模态框的保存提交按钮
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.form);

          if (!this.isEdit) {
            Request.create(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('添加成功');
                this.searchParams.page = 1;
                this.getTableList();
              } else {
                this.msgError('添加失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          } else {
            data.id = this.idEdit;
            Request.update(data).then((res) => {
              if (res && res.code === 200) {
                this.msgSuccess('编辑成功');
                this.getTableList();

                if (this.drawerDetailVisible) this.drawerDetailObj = Object.assign(this.drawerDetailObj, data);
              } else {
                this.msgError('编辑失败：' + res.msg);
              }
              this.dialogVisible = false;
            });
          }
        } else {
          return false;
        }
      });
    },

    //列表里操作列删除按钮弹出提示框的 确定 按钮
    handleDeleteConfirm(row) {
      let loading = this.$loading({ background: 'rgba(0, 0, 0, 0.6)' });

      Request.remove(row.id).then((res) => {
        if (res && res.code == 200) {
          this.msgSuccess('删除成功');
          loading.close();
          this.getTableList();

          if (this.drawerDetailVisible) this.drawerDetailVisible = false;
        }
      });
    },

    //点击列表row右侧弹出详情框
    handleDrawerDetailVisible(row) {
      this.drawerDetailObj = row;
      this.drawerDetailVisible = true;
    },
  },
};
</script>

<style lang="scss" scope></style>
