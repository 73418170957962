import axios from './index';
export default {
  // 获取所有
  listAll: () => {
    return axios.get('/user-charge-record/listall');
  },

  // 分页查询
  page: (params) => {
    return axios.get('/user-charge-record/listpage', { params });
  },

  // // 根据ID获取
  // getById: (id) => {
  //   return axios.get(`/user-charge-record/${id}`);
  // },

  // 新增
  create: (data) => {
    return axios.post('/user-charge-record', data);
  },

  // 编辑
  update: (data) => {
    return axios.put('/user-charge-record', data);
  },

  // 删除
  remove: (id) => {
    return axios.delete(`/user-charge-record/${id}`);
  },

  // -----------------------------
  // 分页查询
  pagemMy: (params) => {
    return axios.get('/user-charge-record/my/listpage', { params });
  },

  // 编辑
  refund: (data) => {
    return axios.put('/user-charge-record/refund', data);
  },
  // 分页查询
  pagemRefund: (params) => {
    return axios.get('/user-charge-record/refund/listpage', { params });
  },
};
